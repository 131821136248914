import React, { useContext, useState } from 'react'
import { Row, Col, ButtonGroup, Button } from 'shards-react'

import { AppContext } from '../appContext'

import Programme from './components/programme'
import AddAwardModal from './components/addAwardModal'
import AddModuleModal from './components/addModuleModal'
import AddProgrammeModal from './components/addProgrammeModal'

function NewFetch() {
  const { programmes } = useContext(AppContext)
  const [state, setState] = programmes

  const [awardOpen, setAwardOpen] = useState(false)
  const toggleAwardOpen = () => setAwardOpen(!awardOpen)

  const [moduleOpen, setModuleOpen] = useState(false)
  const toggleModuleOpen = () => setModuleOpen(!moduleOpen)

  const [programmeOpen, setProgrammeOpen] = useState(false)
  const toggleProgrammeOpen = () => setProgrammeOpen(!programmeOpen)

  return (
    <div className="App-legacy-fetch">
      <div
        className="create-toolbar"
        style={{ marginLeft: '50px', marginTop: '20px' }}
      >
        <ButtonGroup size="sm" className="mr-2">
          <Button onClick={toggleProgrammeOpen}>Add a Degree Programme</Button>
          <Button onClick={toggleAwardOpen}>Add a Exit Award</Button>
          <Button onClick={toggleModuleOpen}>Add a Module</Button>
        </ButtonGroup>
      </div>
      <Row>
        {state.map((programme, key) => (
          <Col>
            <Programme programme={programme} key={key} />
          </Col>
        ))}
      </Row>

      <AddAwardModal open={awardOpen} toggle={toggleAwardOpen} />
      <AddModuleModal open={moduleOpen} toggle={toggleModuleOpen} />
      <AddProgrammeModal open={programmeOpen} toggle={toggleProgrammeOpen} />
    </div>
  )
}

export default NewFetch
