import React, { useState, useContext, useEffect, useCallback } from 'react'

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  ListGroup,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
} from 'shards-react'

import { AppContext } from '../../appContext'

import Award from './award'
import Module from './module'

function Programme({ programme }) {
  let { programmes, awards, modules } = useContext(AppContext)
  const [programmesState, setProgrammesState] = programmes
  const [awardsState, setAwardsState] = awards
  const [modulesState, setModulesState] = modules

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  let [editOpen, setEditOpen] = useState(false)
  let [deleteOpen, setDeleteOpen] = useState(false)

  const toggleEdit = () => setEditOpen(!editOpen)
  const toggleDelete = () => setDeleteOpen(!deleteOpen)

  const handleNameChange = (event, key) => {
    let newName = event.target.value
    programme.name = newName
    let newState = programmesState
    newState[programme.id] = programme
    setProgrammesState(() => newState)
    forceUpdate()
  }

  const handleLOsChange = (event) => {
    let newLOs = event.target.value.split(',')
    programme.learning_outcomes = newLOs
    let newState = programmesState
    newState[programme.id] = programme
    setProgrammesState(() => newState)
    forceUpdate()
  }

  const handleAwardsChange = (event, key) => {
    if (programme.awards.includes(key)) {
      let index = programme.awards.indexOf(key)
      programme.awards.splice(index, 1)
      let newState = programmesState
      newState[programme.id] = programme
      setProgrammesState(() => newState)
    } else {
      let newState = programmesState
      programme.awards.push(key)
      newState[programme.id] = programme
    }
    forceUpdate()
  }

  const handleModulesChange = (event, key) => {
    if (programme.modules.includes(key)) {
      let index = programme.modules.indexOf(key)
      programme.modules.splice(index, 1)
      let newState = programmesState
      newState[programme.id] = programme
      setProgrammesState(() => newState)
    } else {
      let newState = programmesState
      programme.modules.push(key)
      newState[programme.id] = programme
    }
    forceUpdate()
  }

  const handleLedByChange = (event) => {
    let newLedBy = event.target.value
    programme.led_by = newLedBy
    let newState = programmesState
    newState[programme.id] = programme
    setProgrammesState(() => newState)
    forceUpdate()
  }

  const handleDelete = () => {
    let newState
    if (programmesState.length > 1) {
      newState = programmesState
      newState.splice(programme.id, 1)
    } else {
      newState = []
    }
    console.log(newState)
    toggleDelete()
    setProgrammesState(() => newState)
    programme = newState.find((o) => o.id === programme.id)

    forceUpdate()
  }

  console.log(programme)

  return (
    <>
      <Card
        style={{ maxWidth: '500px', margin: '50px' }}
        className="App-programme"
      >
        <CardHeader>{programme.name}</CardHeader>
        <CardBody>
          <CardTitle>Learning Outcomes:</CardTitle>
          {programme.learning_outcomes.map((LO, key) => (
            <Badge theme="light" style={{ marginRight: '5px' }}>
              {LO}
            </Badge>
          ))}
        </CardBody>
        <CardBody>
          <CardTitle>Exit Awards:</CardTitle>
          <ListGroup>
            {programme.awards.map((awardID) => (
              <Award award={awardsState[awardID]} />
            ))}
          </ListGroup>
        </CardBody>
        <CardBody>
          <CardTitle>Modules:</CardTitle>
          <ListGroup>
            {programme.modules.map((moduleID) => (
              <Module module={modulesState[moduleID]} />
            ))}
          </ListGroup>
        </CardBody>
        <CardFooter>Led by {programme.led_by}</CardFooter>
        <ButtonGroup>
          <Button theme="warning" onClick={toggleEdit}>
            Edit
          </Button>
          <Button theme="danger" onClick={toggleDelete}>
            Delete
          </Button>
        </ButtonGroup>
      </Card>

      <Modal open={editOpen} toggle={toggleEdit}>
        <ModalHeader>Edit {programme.name}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <label htmlFor="#name">Name</label>
              <FormInput
                id="#name"
                value={programme.name}
                onChange={(e) => handleNameChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="#LO">Learning Outcomes</label>
              <FormInput
                type="text"
                id="#LO"
                value={programme.learning_outcomes}
                onChange={(e) => handleLOsChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="#awards">Awards</label>
              <br />
              {awardsState.map((award, key) => (
                <FormCheckbox
                  inline
                  id="#awards"
                  checked={programme.awards.includes(key)}
                  onChange={(e) => handleAwardsChange(e, key)}
                >
                  {award.name}
                </FormCheckbox>
              ))}
            </FormGroup>

            <FormGroup>
              <label htmlFor="#ledby">Led By</label>
              <FormInput
                type="text"
                id="#ledby"
                value={programme.led_by}
                onChange={(e) => handleLedByChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="#modules">Modules</label>
              <br />
              {modulesState.map((module, key) => (
                <FormCheckbox
                  inline
                  id="#modules"
                  checked={programme.modules.includes(key)}
                  onChange={(e) => handleModulesChange(e, key)}
                >
                  {module.name}
                </FormCheckbox>
              ))}
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <Modal open={deleteOpen} toggle={toggleDelete}>
        <ModalHeader>Delete {programme.name}?</ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button theme="primary" onClick={toggleDelete}>
              No, don't delete "{programme.name}""
            </Button>
            <Button theme="danger" onClick={handleDelete}>
              Yes, delete "{programme.name}" permanently
            </Button>
          </ButtonGroup>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Programme
