import React, { useContext, useState } from 'react'
import {
  FormInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  FormCheckbox,
} from 'shards-react'

import { AppContext } from '../../appContext'

function AddProgrammeModal({ open, toggle }) {
  const { programmes, modules, awards } = useContext(AppContext)
  const [state, setState] = programmes
  const [modulesState, setModulesState] = modules
  const [awardsState, setAwardsState] = awards

  const [name, setName] = useState('')
  const [LOs, setLOs] = useState('')
  const [tempAwards, setAwards] = useState([])
  const [ledBy, setLedBy] = useState('')
  const [tempModules, setModules] = useState([])

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleLOsChange = (event) => {
    setLOs(event.target.value.split(','))
  }

  const handleAwardsChange = (event, key) => {
    if (tempAwards.includes(key)) {
      let index = tempAwards.indexOf(key)
      let newTempAwards = tempAwards.slice()
      newTempAwards.splice(index, 1)
      setAwards(newTempAwards)
    } else {
      setAwards((state) => [...state, key])
    }
  }

  const handleLedByChange = (event) => {
    setLedBy(event.target.value)
  }

  const handleModulesChange = (event, key) => {
    if (tempModules.includes(key)) {
      let index = tempModules.indexOf(key)
      let newTempModules = tempModules.slice()
      newTempModules.splice(index, 1)
      setModules(newTempModules)
    } else {
      setModules((state) => [...state, key])
    }
  }

  const handleAdd = () => {
    setState(() => [
      ...state,
      {
        name: name,
        learning_outcomes: LOs,
        awards: tempAwards,
        led_by: ledBy,
        modules: tempModules,
      },
    ])
    toggle()
    setName('')
    setLOs(0)
  }

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader>Add a Degree Programme</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <label htmlFor="#name">Name</label>
            <FormInput
              id="#name"
              value={name}
              onChange={(e) => handleNameChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#lo">Learning Outcomes</label>
            <FormInput
              id="#lo"
              value={LOs}
              onChange={(e) => handleLOsChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#award">Awards</label>
            <br />
            {awardsState.map((award, key) => (
              <FormCheckbox
                inline
                id="#award"
                checked={tempAwards.includes(key)}
                onChange={(e) => handleAwardsChange(e, key)}
              >
                {award.name}
              </FormCheckbox>
            ))}
          </FormGroup>
          <FormGroup>
            <label htmlFor="#ledby">Led By</label>
            <FormInput
              id="#ledby"
              value={ledBy}
              onChange={(e) => handleLedByChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#modules">Modules</label>
            <br />
            {modulesState.map((module, key) => (
              <FormCheckbox
                inline
                id="#modules"
                checked={tempModules.includes(key)}
                onChange={(e) => handleModulesChange(e, key)}
              >
                {module.name}
              </FormCheckbox>
            ))}
          </FormGroup>
        </Form>
        <Button theme="success" onClick={handleAdd}>
          Add Degree Programme
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default AddProgrammeModal
