import React, { useState } from 'react'

const AppContext = React.createContext([{}, () => {}])

const defaultState = {
  programmes: [
    {
      id: 0,
      name: 'Undergraduate',
      learning_outcomes: ['LO1', 'LO2', 'LO3'],
      awards: [0, 2],
      led_by: 'Dave',
      modules: [0, 3],
    },
    {
      id: 1,
      name: 'Post-Graduate',
      learning_outcomes: ['LO1', 'LO2', 'LO3'],
      awards: [0, 1],
      led_by: 'Hari',
      modules: [2],
    },
    {
      id: 2,
      name: 'Research',
      learning_outcomes: ['LO1', 'LO2', 'LO3'],
      awards: [2],
      led_by: 'Jake',
      modules: [0, 2, 3],
    },
  ],
  modules: [
    {
      id: 0,
      name: 'Web Application Development',
      hours: 120,
      credits: 60,
      assessments: [
        {
          title: 'CW1',
          number: 1,
          learning_outcomes: ['LO1'],
          volume: '1000 words',
          weighting: 20,
          submission_date: '2020-03-15',
        },
        {
          title: 'CW2',
          number: 2,
          learning_outcomes: ['LO2'],
          volume: '4000 words',
          weighting: 80,
          submission_date: '2020-03-15',
        },
      ],
      led_by: 'Hari',
      timeslots: [1],
    },
    {
      id: 1,
      name: 'Big Data',
      hours: 120,
      credits: 60,
      assessments: [
        {
          title: 'CW1',
          number: 1,
          learning_outcomes: ['LO1'],
          volume: '1000 words',
          weighting: 20,
          submission_date: '2020-03-15',
        },
        {
          title: 'CW2',
          number: 2,
          learning_outcomes: ['LO2'],
          volume: '4000 words',
          weighting: 80,
          submission_date: '2020-03-15',
        },
      ],
      led_by: 'Dave',
      timeslots: [2],
    },
    {
      id: 2,
      name: 'Data Mining',
      hours: 120,
      credits: 60,
      assessments: [
        {
          title: 'CW1',
          number: 1,
          learning_outcomes: ['LO1'],
          volume: '1000 words',
          weighting: 20,
          submission_date: '2020-03-15',
        },
        {
          title: 'CW2',
          number: 2,
          learning_outcomes: ['LO2'],
          volume: '4000 words',
          weighting: 80,
          submission_date: '2020-03-15',
        },
      ],
      led_by: 'Jake',
      timeslots: [0],
    },
    {
      id: 3,
      name: 'Database Design',
      hours: 120,
      credits: 60,
      assessments: [
        {
          title: 'CW1',
          number: 1,
          learning_outcomes: ['LO1', 'LO2'],
          volume: '1000 words',
          weighting: 70,
          submission_date: '2020-03-15',
        },
      ],
      led_by: 'Hari',
      timeslots: [4],
    },
  ],
  timeslots: [
    {
      id: 0,
      room: 'room 1',
      dates: ['2020-02-1', '2020-02-8', '2020-02-15'],
    },
    {
      id: 1,
      room: 'room 3',
      dates: ['2020-02-3', '2020-02-11', '2020-02-14'],
    },
    {
      id: 2,
      room: 'room 2',
      dates: ['2020-02-7', '2020-02-14', '2020-02-21'],
    },
    {
      id: 3,
      room: 'room 5',
      dates: ['2020-02-7', '2020-02-14', '2020-02-21'],
    },
    {
      id: 4,
      room: 'room 4',
      dates: ['2020-02-7', '2020-02-14', '2020-02-21'],
    },
  ],
  awards: [
    {
      id: 0,
      required_credits: '90',
      name: 'Outstanding Web Award',
    },
    {
      id: 1,
      required_credits: '90',
      name: 'Outstanding Data Mining Award',
    },
    {
      id: 2,
      required_credits: '80',
      name: 'Great Web Award',
    },
  ],
}

const AppProvider = (props) => {
  const [programmesState, setProgrammesState] = useState(
    defaultState.programmes,
  )
  const [modulesState, setModulesState] = useState(defaultState.modules)
  const [timeslotsState, setTimeslotsState] = useState(defaultState.timeslots)
  const [awardsState, setAwardsState] = useState(defaultState.awards)

  return (
    <AppContext.Provider
      value={{
        programmes: [programmesState, setProgrammesState],
        modules: [modulesState, setModulesState],
        timeslots: [timeslotsState, setTimeslotsState],
        awards: [awardsState, setAwardsState],
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
