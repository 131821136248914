import React, { useContext, useState } from 'react'
import {
  FormInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from 'shards-react'

import { AppContext } from '../../appContext'

function AddAwardModal({ open, toggle }) {
  const { awards } = useContext(AppContext)
  const [awardsState, setAwardsState] = awards

  const [tempAwardName, setTempAwardName] = useState('')
  const [tempAwardCredits, setTempAwardCredits] = useState(0)

  const handleAwardNameChange = (event) => {
    setTempAwardName(event.target.value)
  }

  const handleAwardCreditsChange = (event) => {
    setTempAwardCredits(event.target.value)
  }

  const handleAddAward = () => {
    setAwardsState(() => [
      ...awardsState,
      { name: tempAwardName, required_credits: tempAwardCredits },
    ])
    toggle()
    setTempAwardName('')
    setTempAwardCredits(0)
  }

  console.log(open)

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader>Add a Exit Award</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <label htmlFor="#name">Name</label>
            <FormInput
              id="#name"
              value={tempAwardName}
              onChange={(e) => handleAwardNameChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#credits">Required Credits</label>
            <FormInput
              id="#credits"
              value={tempAwardCredits}
              onChange={(e) => handleAwardCreditsChange(e)}
            />
          </FormGroup>
        </Form>
        <Button theme="success" onClick={handleAddAward}>
          Add Exit Award
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default AddAwardModal
