import React, { useState } from 'react'

import { Button, Collapse, ListGroup, ListGroupItem } from 'shards-react'

function Award({ assessment }) {
  let [collapse, setCollapse] = useState(false)

  const toggle = () => setCollapse(!collapse)

  return (
    <>
      <Button onClick={toggle}>{assessment.title}</Button>
      <Collapse open={collapse}>
        <ListGroup small>
          <ListGroupItem>Number: {assessment.number}</ListGroupItem>
          <ListGroupItem>
            Learning Outcomes: {assessment.learning_outcomes}
          </ListGroupItem>
          <ListGroupItem>Volume: {assessment.volume}</ListGroupItem>
          <ListGroupItem>Weighting: {assessment.weighting}</ListGroupItem>
          <ListGroupItem>
            Submission Date: {assessment.submission_date}
          </ListGroupItem>
        </ListGroup>
      </Collapse>
    </>
  )
}

export default Award
