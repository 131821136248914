import React, { useState, useContext } from 'react'

import { AppContext } from '../../appContext'

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
} from 'shards-react'

import Assessment from './assessment'

function Module({ module }) {
  let { programmes, awards, modules, timeslots } = useContext(AppContext)
  const [modulesState, setModulesState] = modules
  const [timeslotsState, setTimeslotsState] = timeslots

  let [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <>
      <Button theme="light" onClick={toggle} style={{ marginBottom: '5px' }}>
        {module.name}
      </Button>
      <Modal open={open} toggle={toggle}>
        <ModalHeader>{module.name}</ModalHeader>
        <ModalBody>
          <ListGroup small>
            <ListGroupItem>Hours: {module.hours}</ListGroupItem>
            <ListGroupItem>Credits: {module.credits}</ListGroupItem>
            <ListGroupItem>
              Assessments: <br />
              {module.assessments.map((assessment) => (
                <Assessment assessment={assessment} />
              ))}
            </ListGroupItem>
            <ListGroupItem>
              timeslots:{' '}
              {module.timeslots.map((timeslotID) => (
                <ListGroup small>
                  <ListGroupItem>
                    Room: {timeslotsState[timeslotID].room}
                  </ListGroupItem>
                  <ListGroupItem>
                    Dates:{' '}
                    {timeslotsState[timeslotID].dates.map((date) => (
                      <>{date}, </>
                    ))}
                  </ListGroupItem>
                </ListGroup>
              ))}
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter>Led by: {module.led_by}</ModalFooter>
      </Modal>
    </>
  )
}

export default Module
