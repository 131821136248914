import React from 'react'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
} from 'shards-react'

import data from '../data/module-1.json'

function LegacyFetch() {
  console.log(data)
  return (
    <div className="App-legacy-fetch">
      <Row>
        {data.map((programme, key) => (
          <Col>
            <Card
              style={{ maxWidth: '500px', margin: '30px', padding: '30px' }}
              className="App-programme"
              key={key}
            >
              <CardHeader>{programme.Name}</CardHeader>
              <CardBody>
                <CardTitle>{programme.Course}</CardTitle>
                <p>Assignments:</p>
                <table>
                  <thead>
                    <tr>
                      {programme.Module.Assignment.map((assignment, key) => (
                        <th key={key}>{assignment}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {programme.Module['Learning_outcomes'].map((LO, key) => (
                        <th key={key}>{LO}</th>
                      ))}
                    </tr>
                    <tr>
                      {programme.Module.Volume.map((volume, key) => (
                        <th key={key}>{volume}</th>
                      ))}
                    </tr>
                    <tr>
                      {programme.Module.weights.map((weight, key) => (
                        <th key={key}>{weight}</th>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </CardBody>
              <CardFooter>Edit | Delete</CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default LegacyFetch
