import React, { useState } from 'react'

import { Button, Modal, ModalBody, ModalHeader } from 'shards-react'

function Award({ award }) {
  let [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <>
      <Button theme="light" onClick={toggle} style={{ marginBottom: '5px' }}>
        {award.name}
      </Button>
      <Modal open={open} toggle={toggle}>
        <ModalHeader>{award.name}</ModalHeader>
        <ModalBody>Required Credits: {award.required_credits}</ModalBody>
      </Modal>
    </>
  )
}

export default Award
