import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'shards-react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'shards-ui/dist/css/shards.min.css'

import { AppProvider } from './appContext'

import LegacyFetch from './pages/legacy-fetch'
import NewFetch from './pages/new-fetch'

function App() {
  return (
    <Router>
      <AppProvider>
        <div className="App">
          <header className="App-header">
            <Navbar type="dark" theme="primary" expand="md">
              <NavbarBrand>Academic Management App</NavbarBrand>
              <Nav navbar>
                <NavItem>
                  <a inactive>
                    <NavLink>Fetch (Legacy)</NavLink>
                  </a>
                </NavItem>
                <NavItem>
                  <Link to="/">
                    <NavLink>New Fetch</NavLink>
                  </Link>
                </NavItem>
              </Nav>
            </Navbar>
          </header>
          <Switch>
            <Route path="/legacyfetch">
              <LegacyFetch></LegacyFetch>
            </Route>
            <Route path="/">
              <NewFetch></NewFetch>
            </Route>
          </Switch>
          <footer className="App-header">
            <p>Developed by Sigmundur Mørkøre</p>
            <p>Email: 24001007@edgehill.ac.uk</p>
            <p>Student ID: 24001007</p>
          </footer>
        </div>
      </AppProvider>
    </Router>
  )
}

export default App
