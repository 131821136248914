import React, { useContext, useState } from 'react'
import {
  FormInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  FormCheckbox,
} from 'shards-react'

import { AppContext } from '../../appContext'

function AddModuleModal({ open, toggle }) {
  const { modules, timeslots } = useContext(AppContext)
  const [state, setState] = modules
  const [timeslotsState, setTimeslotsState] = timeslots

  const [tempName, setTempName] = useState('')
  const [tempCredits, setTempCredits] = useState(0)
  const [tempTimeslots, setTimeslots] = useState([])

  const handleNameChange = (event) => {
    setTempName(event.target.value)
  }

  const handleCreditsChange = (event) => {
    setTempCredits(event.target.value)
  }

  const handleTimeslotsChange = (event) => {
    setTempCredits(event.target.value)
  }

  const handleAdd = () => {
    setState(() => [
      ...state,
      {
        id: 0,
        name: 'Web Application Development',
        hours: 120,
        credits: 60,
        assessments: [
          {
            title: 'CW1',
            number: 1,
            learning_outcomes: ['LO1'],
            volume: '1000 words',
            weighting: 20,
            submission_date: '2020-03-15',
          },
          {
            title: 'CW2',
            number: 2,
            learning_outcomes: ['LO2'],
            volume: '4000 words',
            weighting: 80,
            submission_date: '2020-03-15',
          },
        ],
        led_by: 'Hari',
        timeslots: [1],
      },
    ])
    toggle()
    setTempName('')
    setTempCredits(0)
  }

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader>Add a Module</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <label htmlFor="#name">Name</label>
            <FormInput
              id="#name"
              value={tempName}
              onChange={(e) => handleNameChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#hours">Hours</label>
            <FormInput
              id="#hours"
              value={tempCredits}
              onChange={(e) => handleCreditsChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#credits">Credits</label>
            <FormInput
              id="#credits"
              value={tempCredits}
              onChange={(e) => handleCreditsChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#credits">Assessments</label>
            <FormInput
              id="#credits"
              value={tempCredits}
              onChange={(e) => handleCreditsChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#credits">Led By</label>
            <FormInput
              id="#credits"
              value={tempCredits}
              onChange={(e) => handleCreditsChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#timeslots">Timeslots</label>
            <br />
            {timeslotsState.map((timeslot, key) => (
              <FormCheckbox
                inline
                id="#timeslots"
                checked={tempTimeslots.includes(key)}
                onChange={(e) => handleTimeslotsChange(e, key)}
              >
                {timeslot.room}: {timeslot.dates.map((date) => date + ', ')}
              </FormCheckbox>
            ))}
          </FormGroup>
        </Form>
        <Button theme="success" onClick={handleAdd}>
          Add Module
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default AddModuleModal
